'use client';

import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { FaXmark } from 'react-icons/fa6';

import { setCookieAppBannerHide } from '@/features/roomster-app/cookies/cookie-app-banner-hide';
import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';

import styles from './app-banner.module.scss';

interface AppBannerProps {
  isBannerClosed: boolean,
  setIsBannerClosed: (isClosed: boolean) => void,
}

export default function AppBanner(props: AppBannerProps) {
  const {
    isBannerClosed,
    setIsBannerClosed,
  } = props;

  const translationsAppBanner = useTranslations('roomster-app.app-banner');
  const appFlyerLink = useAppFlyerLink('mobile', 'mobileTopBanner');

  const handleCloseBanner = () => {
    setIsBannerClosed(true);
    setCookieAppBannerHide(true);
  };

  const handleGetAppClick = () => {
    handleCloseBanner();
  };

  return !isBannerClosed && (
    <div id="app-banner" className={styles.appBanner}>
      <div className={styles.appBanner_container}>
        <div className={styles.appBanner_content}>
          <button
            className={styles.appBanner_close}
            onClick={handleCloseBanner}
            aria-label="close"
          >
            <FaXmark className={styles.appBanner_closeIcon} />
          </button>

          <a
            className={styles.appBanner_content}
            onClick={handleGetAppClick}
            href={appFlyerLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.banner_logo}>
              <Image src="/images/roomster-logo/logo-white.svg" width={28} height={28} alt="roomster logo" />
            </div>

            <div className={styles.appBanner_info}>
              <p className={styles.banner_title}>
                {translationsAppBanner('title')}
              </p>

              <p className={styles.banner_text}>
                {translationsAppBanner('text')}
              </p>
            </div>

            <div className={styles.appBanner_button}>
              {translationsAppBanner('button')}
            </div>
          </a>

          {/* <div className={styles.appBanner_imageContainer}> */}
          {/*  <Image */}
          {/*    loading="eager" */}
          {/*    className={styles.appBanner_image} */}
          {/*    src="/images/roomster-app/app-phones.png" */}
          {/*    alt="roomster app" */}
          {/*    fill */}
          {/*  /> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
