import {
  getCookie,
  setCookie,
} from 'cookies-next/client';
import { getCookie as getCookieServer } from 'cookies-next/server';

import { SECONDS } from '@/shared/constants/time-constants';
import {
  type CookieGetData,
  type CookieReturnValue,
} from '@/shared/types/cookies-types';

const cookieName = 'hideAppBanner';

export function setCookieAppBannerHide(isHideAppBanner: boolean): void {
  setCookie(cookieName, isHideAppBanner, {
    maxAge: SECONDS.DAY,
    path: '/',
  });
}

export function getCookieAppBannerHide<IsServer extends boolean = false>(cookieOptions?: CookieGetData<IsServer>): CookieReturnValue<IsServer, boolean> | boolean {
  if (cookieOptions?.isServer) {
    return (async () => {
      const cookie = await getCookieServer(cookieName, cookieOptions.options);

      return cookie === 'true';
    })() as CookieReturnValue<IsServer, boolean>; // Ensure proper type casting for Promise
  }

  const cookie = getCookie(cookieName);

  return cookie === 'true';
}
